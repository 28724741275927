$(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('.dropdown-toggle').dropdown()


    $(window).resize(function(){
        setContentOuterHeight();
    });

    //Switchery start
    var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));

    elems.forEach(function(elem) {
        //var switchery = new Switchery(html);
        var switchery = new Switchery(elem, { color: '#3FC1BF', secondaryColor: '#eee', jackColor: '#fcf45e', jackSecondaryColor: '#BBEBE8' });

    });
//Switchery end

    $('[data-toggle="popover"]').popover();

    //$('select').select2();

    init();

});




function init(){
    setContentOuterHeight();
}
function setContentOuterHeight(){
    if(window.innerHeight>600){
        $(".content-outer").css('min-height',window.innerHeight-150+'px');
    }
}



function getUpdatedUri(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}


var utility = new Vue({
    data: {

    },
    methods: {
        getCookie:function (cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for(var i=0; i<ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1);
                if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
            }
            return "";
        },
        setCookie:function(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; " + expires+'; path=/';
        },
        processApiResponse:function(response){
            // console.log(response.data);

            if(response.data.status == 'error'){
            }
            return response.data;

        },
    },
})










